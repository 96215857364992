export var config = {
  taxonomy: 'imockr',
  identity: "imockr",
  class: '',
  subclass: '',
  zone: 'GmZ',
  responsePageSize: '10',
  chromeExtension: 'cijooligehaladajikbfjfcoimkibhgn',
  randomQuestions: '5',
  timeDuration: '120',
  buyPrice: '0.0',
  rentPrice: '0.0',
  country: 'USA',
  startTime: '01/01/2024, 10:00:00 AM',
  rightQuestionReward: '4',
  wrongQuestionPenalty: '1',
  audioEnabled: 'false',
  goldimembersAnnualPrice: '24.99',
  goldimembersMonthlyPrice: '4.99',
  goldimembersDailyPrice: '0.99',
  goldimembersEnabled: 'false',
  memberType: 'Goldimembers',
  autoUrl: 'https://auto.siloq.com/',
  idUrl: 'https://api.slqid.com/',
  topicsUrl: 'https://api.imockr.siloq.com/',
  tagsUrl: 'https://api.imockr.siloq.com/',
  usersUrl: 'https://api.imockr.siloq.com/',
  searchUrl: 'https://srch.siloq.com/',
  tinUrl: 'https://tin.siloq.com/',
  slqioUrl: 'https://slqio.com/',
  slqioApiUrl: 'https://api.slqio.com/',
  telUrl: 'https://tel.siloq.com/',
  syncUrl: 'https://sync.siloq.com/',
  siloqUrl: 'https://tin.siloq.com/',
  siloqpayUrl: 'https://pay.siloq.com/',
  apiSiloqpayUrl: 'https://api.pay.siloq.com/',
  siloqpayCallback: 'imockr.siloq',
  currency: 'USD',
  admin: ["neet.ind.imockr@siloq.com", "usc.usa.imockr@siloq.com", "palani@siloq.com", "palani.thangaraj@siloq.com", "varadarajan.periasamy@siloq.com", "tpalanisamy@yahoo.com", "varadarajan2020@gmail.com"],
  specialChars: []
}
